:root {
  --background-col: rgb(245, 245, 246);
  --black: #000000;
  --error-color: red;
  --kitco-yellow: #eeb111;
  --light-bg-col: rgba(189, 192, 194, 0.125);
  --link-col: #eeb111;
  --dark-gray: #555;
  --light-gray: rgb(194, 190, 190);
  --white: #ffffff;
  --kitco-bg-image: url('../public/images/Dotted_Background.png') repeat right top;
}

* {
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
}

html {
  height: 100%;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 14px;
  line-height: 1.5;
  height: 100%;
  margin: 0;
  color: #24292e;
  background-color: #fff;
}

#root {
  height: 100%;
}

.alert {
  padding: 1.25rem;
  box-shadow: 0px 0px 1px 0px black;
  width: 25rem;
  min-height: 3rem;
  background-color: white;
  color: var(--error-color);
  position: fixed;
  top: 6rem;
  right: 1rem;
  z-index: 15;
}

.alert-text {
  max-width: 90%;
  max-height: 80vh;
  scroll-behavior: auto;
  overflow-y: scroll;
  font-size: 1.5rem;
  word-wrap: break-word;
}

.bank-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.account-card-2 {
  width: 24%;
}

.account-card-4 {
  width: 30%;
  max-width: 30rem;
}

.account-card-content {
  padding: 0rem;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.account-card-content form {
  height: 100%;
  width: 100%;
}

.account-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.account-instruction {
  margin: 2rem 1rem 0;
}

.account-instruction-link {
  margin: 0.5rem auto;
}

.account-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 86%;
  margin: auto;
}

.account-wrapper .account-card-2,
.account-wrapper .account-card-4 {
  min-height: 30rem;
  padding: 1.875rem 2.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
}

.app {
  margin: 2rem;
}

.animated {
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

.bank-acc {
  margin-left: 2rem;
  font-size: 1rem;
}

.bank-acc .title {
  font-weight: 500;
}

.bank-acc .desc {
  margin-bottom: 0.375rem;
}

.bank-info-text {
  max-width: 26rem;
  margin: 0 auto 1.25rem;
  text-align: center;
}

.bottom-margin {
  margin-bottom: 2.5rem;
}

.bottom-margin-1 {
  margin-bottom: 1.5rem !important;
}

.btn {
  padding: 12px 20px;
  border-style: none;
  border-radius: 2rem;
  background-color: var(--kitco-yellow);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--white);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.25;
}

button {
  margin: 0 auto;
  padding: 12px 20px;
  border-style: none;
  border-radius: 2rem;
  background-color: var(--kitco-yellow);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
  font-size: 1.125rem;
  font-weight: 500;
  color: var(--white);
  cursor: pointer;
  outline: none;
  -webkit-appearance: none;
  text-transform: uppercase;
  transition: all 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
}

button:disabled {
  /* opacity: 0.5; */
  background-color: var(--light-gray);
  cursor: not-allowed !important;
}

button:hover,
.btn:hover {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.24), 0 7px 20px 0 rgba(0, 0, 0, 0.19);
  transform: translateY(-1px);
}

button.link {
  all: unset;
  text-decoration: underline;
  color: var(--link-col);
  cursor: pointer;
}

button.outline {
  background-color: #eee;
  border: 1px solid #aaa;
  color: var(--dark-gray);
}

.button-primary {
  width: 15rem;
}

.button-secondary {
  width: 10rem;
}

.card {
  max-width: 38rem;
  min-height: 32rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: auto;
  padding: 3rem 5rem;
  background-color: var(--white);
}

.card img {
  margin: 0 auto;
}

.card .button-primary {
  margin: 1.25rem auto;
}

.card-small {
  max-width: 38rem;
  margin: auto;
  padding: 3rem;
  background-color: var(--white);
}

.card-small .button-primary {
  margin: 1.25rem auto;
}

.card-small p:first-child {
  margin-top: 0;
}

.card-small .sub-card {
  width: 80%;
  margin: 2rem auto 0;
}

.center-align-container {
  display: flex;
  justify-content: space-around;
}

.center-align-vertical-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0;
}

.center-align-vertical-container p {
  margin-bottom: 0.125rem;
}

.center-align .content {
  align-self: center;
  margin: auto;
  display: block;
}

.closebtn {
  position: absolute;
  padding: 0.25rem;
  right: 1rem;
  top: 0.5rem;
}

.closebtn:hover {
  color: black;
  cursor: pointer;
}

code {
  background: #eee;
  padding: 0.1rem;
  font-family: 'Menlo';
  font-size: 13px;
  color: #ff00aa;
}

.col-3 {
  width: 32%;
}

.col-5 {
  width: 48%;
}

.col-6 {
  width: 64%;
}

.col-10 {
  width: 100%;
}

.container {
  min-width: 100%;
  min-height: 100%;
  padding-top: 4rem;
  margin-top: 4.725rem;
  position: relative;
}

.container:before {
  position: absolute;
  z-index: -10;
  top: 0rem;
  left: 0;
  content: '';
  min-width: 100%;
  min-height: 100%;
  background: var(--kitco-bg-image);
  background-size: contain;
  opacity: 0.9;
}

.coverup-container {
  position: fixed;
  content: '';
  width: 100vw;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #00000040;
  z-index: 99;
}

.disabled {
  color: #ccc;
}

.display-field-box {
  border: 1px solid var(--light-gray);
  border-radius: 2px;
  padding: 0.375rem;
  width: 100%;
  text-align: center;
  max-width: 100%;
  word-wrap: break-word;
}

.error .input-feedback {
  color: var(--error-color);
}

.error .label {
  color: var(--error-color);
}

.error .text-input {
  border-color: var(--error-color);
}

.flex-row {
  display: flex;
  justify-content: space-between;
}

.flex-row hr {
  width: 100%;
  height: 100%;
}

.home-card {
  width: 48%;
  max-width: 44rem;
  min-height: 32rem;
  padding: 1.875rem 2.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--white);
}

.home-card-title {
  text-align: center;
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 1;
}

.home-container {
  position: relative;
  min-width: 100%;
  min-height: 100%;
  padding: 8rem 0;
}

.home-container:before {
  position: absolute;
  z-index: -10;
  top: 0;
  left: 0;
  content: '';
  min-width: 100%;
  min-height: 100%;
  background: var(--kitco-bg-image);
  background-size: contain;
  opacity: 0.9;
}

.home-user-msg {
  width: 86%;
  font-size: 1.5rem;
  margin: 0 auto 1rem;
  text-transform: uppercase;
  display: flex;
  justify-content: flex-start;
}

.home-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 86%;
  margin: auto;
}

.hovertext:hover .hover-tooltip {
  display: none;
}

.hover-tooltip {
  display: none;
  background: var(--kitco-yellow);
  color: var(--black);
  margin-left: 28px;
  padding: 10px;
  position: absolute;
  z-index: 100;
  width: 100px;
  height: 40px;
  line-height: 1;
  border-radius: 5px;
  top: -85%;
  left: 55%;
}

.input-feedback {
  color: #999;
  min-height: 1.25rem;
}

.input-group {
  padding-bottom: 0.1rem;
  min-height: 4.875rem;
}

.input-text-area-10 {
  width: 100%;
}
.input-text-area-10:active {
  border-color: var(--kitco-yellow);
}

.kgld-color {
  color: var(--kitco-yellow);
}

.kgld-gold-price-wrapper {
  width: 86%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto 2rem;
}

.kgld-gold-price-container {
  display: flex;
  flex-direction: column;
}

.kgld-gold-price-container * {
  margin: 0 auto;
  line-height: 1.2;
}

.kgld-gold-price-container .live-price {
  font-size: 2rem;
  font-weight: 600;
}

.kgld-gold-price-container .spot-text {
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;
}

.kgld-gold-price-container .time {
  font-size: 1rem;
  vertical-align: text-bottom;
}

.label {
  font-weight: bold;
  /* margin-bottom: 0.5rem; */
}

.left-arrow {
  width: 10px;
  height: 0;
  margin-right: 2px;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid var(--black);
  display: inline-block;
  position: relative;
  top: 3px;
  left: 0px;
}

.lang-selector {
  min-width: 6rem;
  max-width: 7rem;
  padding: 0.3rem 0.5rem !important;
}

.link {
  color: var(--link-col);
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link:visited,
.link:active {
  color: var(--link-col);
}
.link-black,
.link-black:hover,
.link-black:visited,
.link-black:active {
  color: var(--black);
  text-decoration: underline;
}

.navbar {
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  color: var(--black);
  background-color: var(--background-col);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.navbar-logo {
  position: relative;
  margin: 0;
  display: flex;
  flex: 1 1 25%;
  align-items: center;
  max-width: 155px;
}

.navbar-logo > img {
  max-width: 155px;
}

.navbar-controls {
  width: 40%;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: var(--black);
}

.navbar-link {
  padding: 0.5rem;
  text-transform: uppercase;
  text-decoration: none;
  color: var(--black);
}

.reject {
  border-radius: 0.25rem;
  color: red; /*rgba(0, 0, 0, 0.5);*/
  background-color: var(--light-gray); /* rgba(249, 28, 28, 0.866);*/
}

.required-asterisk {
  color: red;
}

.select-input {
  position: relative;
  display: inline-block;
  width: 100%;
}

.select-input select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 2.3rem;
  font-size: 16px;
  width: 100%;
  padding: 0 1.25rem 0 0.25rem;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.select-input::after {
  content: '';
  position: absolute;
  right: 10px;
  top: 14px;
  width: 5px;
  height: 5px;
  border-left: 1px solid var(--black);
  border-right: 1px solid transparent;
  border-top: 1px solid transparent;
  border-bottom: 1px solid var(--black);
  transform: rotate(-45deg);
  pointer-events: none;
}

.space-between-container {
  display: flex;
  justify-content: space-between;
}

.step {
  display: flex;
  flex-direction: row;
  align-content: center;
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
}

.step-circle {
  color: var(--kitco-yellow);
  border: 2px solid var(--kitco-yellow);
  border-radius: 50%;
  padding: 1px 8px;
  height: 1.75rem;
  margin-right: 0.375rem;
  margin-top: 0.375rem;
}

.step-text {
  font-size: 1rem;
  margin-top: 0.325rem;
  max-width: 100%;
  word-wrap: break-word;
}

.success-container {
  max-height: 40rem;
  max-width: 30rem;
  margin: 0 auto;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.success-container > * {
  margin: 1rem auto;
}

.success-container img {
  width: 10rem;
  height: 10rem;
}

.success-container p {
  font-size: 1.75rem;
  text-align: center;
}

table.border {
  border: 1px solid lightgray;
  border-collapse: collapse;
}

table.border td,
table.border th {
  border: 1px solid lightgray;
  padding: 4px;
}

table.user-table {
  width: 100%;
}

table.user-table td,
table.user-table th {
  text-align: center;
  border: 1px solid black;
}

table.user-table tr:nth-child(even) {
  background-color: lightgray;
}

.table-wrapper {
  overflow-x: auto;
}

.terms-of-service {
  margin-top: 1.25rem;
}

.terms-of-service a {
  margin: 0 0.15rem;
}

.terms-of-service .label {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.terms-text {
  margin: 1.25rem auto;
  max-width: 20rem;
}

.text-align-center {
  text-align: center;
}

.text-bold {
  font-weight: 600;
  font-size: 1.25rem;
}

.text-bold-big {
  font-weight: 600;
  font-size: 1.5625rem;
}

.text-bold-imp {
  font-weight: 600;
}

.text-bold-small {
  font-weight: 500;
  font-size: 1rem;
}

.text-input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.text-input:focus {
  border-color: var(--kitco-yellow);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

.text-instruction {
  font-size: 1.25rem;
}

.text-uppercase {
  text-transform: uppercase;
}

.title-med {
  font-size: 1.8rem;
  font-weight: 600;
}

.toggle-wrapper {
  position: relative;
  text-align: center;
  text-transform: uppercase;
  max-width: 38rem;
  margin: auto;
  max-height: 2.375rem;
  font-size: 1;
  font-weight: 600;
  color: var(--white);
}

.toggle-wrapper .toggle-left {
  position: absolute;
  cursor: pointer;
  padding: 0.5rem;
  background-color: var(--kitco-yellow);
  width: 50%;
  top: 0;
  left: 0;
  min-height: 2.375rem;
}

.toggle-wrapper .toggle-right {
  position: absolute;
  cursor: pointer;
  padding: 0.5rem;
  background-color: var(--kitco-yellow);
  width: 50%;
  top: 0;
  right: 0;
  min-height: 2.375rem;
}

.toggle-active {
  padding: 0.5rem;
  background-color: var(--white) !important;
  color: var(--kitco-yellow);
}

.toggle-left-arrow {
  width: 0;
  height: 0;
  margin-right: 5px;
  border-top: 19px solid transparent;
  border-left: 10px solid transparent;
  border-bottom: 19px solid var(--white);
  border-right: 10px solid var(--white);
  display: inline-block;
  position: relative;
  z-index: 2;
  top: 0px;
  right: 7px;
}

.toggle-right-arrow {
  width: 0;
  height: 0;
  margin-right: 0px;
  border-top: 19px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 19px solid var(--white);
  border-left: 10px solid var(--white);
  display: inline-block;
  position: relative;
  z-index: 2;
  top: 0px;
  left: 10px;
}

.top-margin-label {
  margin-top: 1rem;
  font-size: 1rem;
}

.twoFA-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.twoFA-container canvas {
  margin: 1.25rem auto;
}

.twoFA-container button {
  margin: 1.25rem auto 0;
}

.underline {
  text-decoration: underline;
  cursor: pointer;
}

.uppercase {
  text-transform: uppercase;
  line-height: 1;
  font-size: 1.5rem;
}

.circle {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 45%;
  left: 45%;
}
.circle .child {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.circle .child:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 18%;
  height: 18%;
  background-color: var(--white);
  border-radius: 100%;
  -webkit-animation: circleBounceDelay 1.2s infinite ease-in-out both;
  animation: circleBounceDelay 1.2s infinite ease-in-out both;
}
.circle .circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}
.circle .circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}
.circle .circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.circle .circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}
.circle .circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}
.circle .circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.circle .circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}
.circle .circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}
.circle .circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.circle .circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}
.circle .circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}
.circle .circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.circle .circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.circle .circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.circle .circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.circle .circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}
.circle .circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.circle .circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}
.circle .circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.circle .circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}
.circle .circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
.circle .circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/*======       KEYFRAMES     ======*/
@keyframes shake {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0);
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0);
  }
}

.shake {
  animation-name: shake;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 97rem),
  only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 97rem),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 97rem),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 97rem),
  only screen and (min-resolution: 192dpi) and (min-width: 97rem) {
  /* high resolution screen stuff here */
  .account-wrapper,
  .home-wrapper,
  .kgld-gold-price-wrapper,
  .home-user-msg {
    max-width: 60% !important;
  }
  .account-wrapper,
  .home-wrapper {
    justify-content: space-around;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2) and (min-width: 67rem),
  only screen and (min--moz-device-pixel-ratio: 2) and (min-width: 67rem),
  only screen and (-o-min-device-pixel-ratio: 2/1) and (min-width: 67rem),
  only screen and (min-device-pixel-ratio: 2) and (min-width: 67rem),
  only screen and (min-resolution: 192dpi) and (min-width: 67rem) {
  .account-wrapper,
  .home-wrapper,
  .kgld-gold-price-wrapper,
  .home-user-msg {
    max-width: 80% !important;
  }
}

@media screen and (min-width: 67rem) {
  .account-wrapper,
  .home-wrapper,
  .kgld-gold-price-wrapper,
  .home-user-msg {
    max-width: 65%;
  }
}

@media screen and (max-width: 62rem) {
  .account-wrapper {
    flex-direction: column;
  }

  .account-card-2,
  .account-card-4 {
    width: 90%;
    margin: 2rem auto;
  }

  .account-card-content {
    padding: 1rem 2rem;
  }

  .col-3,
  .col-5,
  .col-6,
  .col-10 {
    width: 100%;
  }

  .flex-row {
    flex-direction: column;
  }

  .home-card {
    width: 90%;
    min-height: 32rem;
    margin: 1.5rem auto;
    padding: 2rem;
    flex-direction: column;
  }

  .home-container {
    padding-top: 5rem;
  }

  .home-user-msg {
    width: 70%;
    font-size: 1rem;
    margin: 0.75rem auto 0.125rem;
  }

  .home-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin: auto;
  }

  .success-container p {
    font-size: 1.5rem;
    text-align: center;
  }
}

@media screen and (max-width: 25rem) {
  .account-wrapper {
    flex-direction: column;
  }

  .account-card-2,
  .account-card-4 {
    width: 100%;
    margin: 2rem auto;
  }

  .account-card-content {
    padding: 0;
  }

  .col-3,
  .col-5,
  .col-6,
  .col-10 {
    width: 100%;
  }

  .bank-acc {
    margin-left: 2rem;
    font-size: 1rem;
  }

  .button-secondary {
    min-width: 8rem;
  }

  .card {
    max-width: 90%;
    min-height: 100%;
    margin: auto;
    padding: 2rem 1.5rem;
  }

  .card-small {
    max-width: 90%;
    min-height: 100%;
    margin: auto;
    padding: 2rem 1rem;
  }

  .center-align-vertical-container {
    margin: 0;
  }

  .container {
    max-width: 100%;
    min-height: 100%;
  }

  .col-3,
  .col-5,
  .col-6,
  .col-10 {
    width: 100%;
  }

  .flex-row {
    flex-direction: column;
  }

  .home-card {
    width: 100%;
    min-height: 32rem;
    margin: 1rem auto;
    padding: 2rem;
    flex-direction: column;
  }

  .home-container {
    padding-top: 5rem;
  }

  .home-user-msg {
    width: 90%;
    font-size: 1rem;
    margin: 0.75rem auto 0.125rem;
  }

  .home-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 90%;
    margin: auto;
  }

  .kgld-gold-price-wrapper {
    margin-top: 0.625rem;
    justify-content: center;
  }

  .terms-of-service .label {
    align-items: start;
  }

  .toggle-wrapper {
    max-width: 90%;
  }
}
